import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {createRoot} from 'react-dom/client';
import {io, Socket} from 'socket.io-client';
import type {Game, GameUser, LeaderboardUser, REST_API, Socket_API} from './api';
import clsx from 'clsx';
import {
    ClientIdContext,
    useChosenUser,
    useGameAtom,
    useLeaderboard,
    useRoundAtom,
    useRoundUserAtom,
    useSetGameAtom,
    useSetUserAtom,
    useSubscribeState,
    useUserAtom,
    useUserAtomFamily,
    useUserId,
    useUserIdsAtom,
} from './state';

function Client() {
    const setGame = useSetGameAtom();
    const setUser = useSetUserAtom();
    const user = useUserAtom().data;
    const game = useGameAtom().data;
    const [partyInput, setPartyInput] = useState(
        () => 'testgame_' + Math.random().toString(36),
    );
    const createGame = async () => {
        const res = await fetch('/parties/router/$/game', {
            method: 'POST',
            body: JSON.stringify({
                game_name: partyInput,
            }),
        });
        if (!res.ok) {
            return console.error(await res.text());
        }
        const data = (await res.json()) as REST_API['game']['POST']['Response'];
        setUser(data.user);
        setGame(data.game);
    };
    const joinGame = async () => {
        const pairing_code = partyInput;
        const res = await fetch('/parties/router/$/game', {
            method: 'PUT',
            body: JSON.stringify({
                pairing_code,
            }),
        });
        if (!res.ok) {
            return console.error(await res.text());
        }
        const data = (await res.json()) as REST_API['game']['PUT']['Response'];
        setUser(data.user);
        setGame(data.game);
    };
    return (
        <div id="app">
            <div className="flex flex-col gap-2">
                {!user && (
                    <>
                        <div>
                            <Input
                                value={partyInput}
                                onChange={(e) => setPartyInput(e.target.value)}
                            />
                        </div>
                        <div className="flex gap-2">
                            <Button onClick={createGame}>Create Game</Button>
                            <Button onClick={joinGame}>Pairing Code</Button>
                        </div>
                    </>
                )}
                {user && game && <GameUserView />}
            </div>
        </div>
    );
}

function Input({
    value,
    onChange,
    onBlur,
    disabled,
}: {
    value: string;
    onChange: (value: {
        target: EventTarget & HTMLInputElement;
        nativeEvent: Event;
        currentTarget: EventTarget & HTMLInputElement;
        bubbles: boolean;
        cancelable: boolean;
        defaultPrevented: boolean;
        eventPhase: number;
        isTrusted: boolean;
        preventDefault: () => void;
        isDefaultPrevented: () => boolean;
        stopPropagation: () => void;
        isPropagationStopped: () => boolean;
        persist: () => void;
        timeStamp: number;
        type: string;
    }) => void;
    onBlur?: () => void;
    disabled?: boolean;
}) {
    return (
        <input
            className="border-2 border-gray-800 rounded-sm p-1 w-full"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
        />
    );
}

function Button({
    onClick,
    children,
    disabled,
}: {
    onClick: () => void;
    children: string;
    disabled?: boolean;
}) {
    return (
        <button
            disabled={disabled}
            className="bg-blue-500 hover:bg-blue-700 active:bg-blue-900 text-white font-bold py-1 px-2 rounded"
            onClick={onClick}
        >
            {children}
        </button>
    );
}

const SocketContext = createContext<Socket | undefined>(undefined);
function useSocketContext() {
    return useContext(SocketContext);
}
function GameUserView() {
    const game = useGameAtom().data;
    const user = useUserAtom().data;
    const socket = useSocket(game?.party_id, {
        user_id: user?.user_id,
        user_secret: user?.user_secret,
    });
    useSubscribeState(socket);
    return (
        <div className="w-full">
            {socket && (
                <SocketContext.Provider value={socket}>
                    <GameSocketView />
                </SocketContext.Provider>
            )}
            <pre className="text-xs">{JSON.stringify(game, null, '  ')}</pre>
            <pre className="text-xs">{JSON.stringify(user, null, '  ')}</pre>
        </div>
    );
}

function GameSocketView() {
    const game = useGameAtom().data;
    const user = useUserAtom().data;
    const roundUser = useRoundUserAtom();
    if (!user || !game) return <div>loading...</div>;
    return (
        <>
            <EditUser />
            <div>Game</div>
            {game.phase === 'lobby' && (
                <>
                    {user.user_id === game.host_user_id ? (
                        <EditGameLobby />
                    ) : (
                        <div>
                            <div>Game Name: {game.game_name}</div>
                            <div>Category: {game.category}</div>
                            <div>Round Limit: {game.round_limit}</div>
                        </div>
                    )}
                </>
            )}
            {game.phase !== 'lobby' && <Countdown />}
            {game.phase === 'round_start' && <RoundStart />}
            {game.phase === 'finding_story' && roundUser && <FindingStory />}
            {game.phase === 'story_confirm' && <ConfirmStory />}
            {game.phase === 'guessing' && <Guessing />}
            {game.phase === 'round_over' && <div>Round Over</div>}
            {game.phase === 'leaderboard' && <Leaderboard />}
            {game.phase === 'results' && (
                <div>
                    <div className="text-xl">Results:</div>
                    <Results />
                </div>
            )}
            <SubscribeViewUsers />
            <Received />
        </>
    );
}

function FindingStory() {
    const user_id = useUserId().data;
    const round = useRoundAtom().data;
    const userRound = useRoundUserAtom().data;
    if (!user_id || !round || !userRound) return <div>loading...</div>;
    if (user_id === round.chosen_user_id) return <ChosenFindingStory />;
    return <PlayerWaitingStory />;
}

function ChosenFindingStory() {
    const [suggestion, setSuggestion] = useState<string>('');
    const [answers, setAnswers] = useState<{text: string; key: string}[]>([]);
    const game = useGameAtom().data;
    const round = useRoundAtom().data;
    const roundUser = useRoundUserAtom().data;
    const socket = useSocketContext();
    const [story, setStory] = useState(roundUser?.challenge?.story || '');
    const [storyA, storyB] = useMemo(() => {
        return roundUser?.challenge?.story?.split('[[]]') || [];
    }, [roundUser?.challenge?.story]);

    const setKeyword = (x: string) => {
        console.log(roundUser?.challenge?.story, roundUser);
        if (typeof roundUser?.challenge?.story !== 'string') return;
        socket?.emit('edit-round-user', {
            changes: ['challenge'],
            round_user: {
                ...roundUser,
                challenge: {
                    ...roundUser.challenge,
                    story: story,
                    keyword: x,
                },
            },
        });
    };
    const storyConfirm = () => {
        if (!roundUser || !game) return;
        socket?.emit('edit-game', {
            changes: ['phase'],
            game: {
                ...game,
                phase: 'story_confirm',
            },
        });
    };
    const run = () => {
        setAnswers([]);
        const suggestion = new EventSource(
            `https://ai.bookeroo.xyz/suggestion?text=${encodeURIComponent(story || '')}`,
        );
        let buffer = '';
        suggestion.onmessage = (e) => {
            if (e.data.includes('[DONE]')) {
                suggestion.close();
                return;
            }
            try {
                const {response} = JSON.parse(e.data) as {response: string; p: string};
                const [answer, newBuffer] = processResponse(response, buffer);
                buffer = newBuffer;
                if (answer) {
                    setAnswers((x) => [
                        ...x,
                        {
                            text: answer,
                            key: Math.random().toString(36),
                        },
                    ]);
                }
            } catch (err) {
                console.error('could not parse', e, err);
            }
        };
        return () => {
            suggestion.close();
        };
    };

    useEffect(() => {
        setKeyword(suggestion);
    }, [suggestion]);
    return (
        <div className="w-full">
            <div className="text-lg">
                Tell a{' '}
                <span className="font-bold uppercase">{roundUser?.challenge?.mode}</span>
            </div>
            <div className="font-bold">Story</div>
            <div className="flex gap-1 mb-1">
                <Input value={story} onChange={(x) => setStory(x.target.value)} />
                <Button onClick={run}>Suggest</Button>
            </div>
            <Input value={suggestion} onChange={(x) => setSuggestion(x.target.value)} />
            <div>
                <span>{storyA}</span>
                <span>{suggestion}</span>
                <span>{storyB}</span>
            </div>
            <Button onClick={storyConfirm}>Confirm</Button>
            <div className="font-bold">Suggestion</div>
            <div className="flex flex-wrap gap-2">
                {answers.map((x) => (
                    <Button key={x.key} onClick={() => setSuggestion(x.text)}>
                        {x.text}
                    </Button>
                ))}
            </div>
            <pre>{JSON.stringify(round, null, '  ')}</pre>
            <pre>{JSON.stringify(roundUser, null, '  ')}</pre>
        </div>
    );
}

// response: empty string | part of word | word | space | word and space | newline | [ | ] | ][ | [] | ][] | [][ | comma (bad)
// buffer: empty string | part of word | word | word and space | phrase
// if response contains ] then return buffer as response, and new buffer
const phrase = /\[[^\]]*\]/;
// eslint-disable-next-line no-useless-escape
const removeBrackets = /[\[\]]/g;
function processResponse(response: string, buffer: string): [string, string] {
    buffer += response;
    const match = buffer.match(phrase);
    if (match) {
        const [phrase] = match;
        const newBuffer = buffer.replace(phrase, '');
        return [phrase.replace(removeBrackets, ''), newBuffer];
    }
    return ['', buffer];
}

function PlayerWaitingStory() {
    const chosenUser = useChosenUser().data;
    if (!chosenUser) return <div>loading...</div>;
    return (
        <div>
            <div className="font-bold">Story</div>
            <div>{chosenUser.name} is finding a story</div>
        </div>
    );
}

function ConfirmStory() {
    return <div className="animate-bounce text-3xl font-bold">Story Confirmed!</div>;
}

function Guessing() {
    const round = useRoundAtom().data;
    const userId = useUserId().data;
    if (!round || !userId || !round.challenge) return <div>loading...</div>;
    if (round.chosen_user_id === userId) return <ChosenGuessing />;
    return <PlayerGuessing />;
}

function ChosenGuessing() {
    const userRound = useRoundUserAtom().data;
    if (!userRound || !userRound.challenge) return <div>loading...</div>;
    const {story, mode, keyword} = userRound.challenge;
    return (
        <div>
            <div className="text-lg">
                <div>Story: {story.replace('[[]]', keyword)}</div>
                <div className="font-bold">Mode: {mode}</div>
            </div>
        </div>
    );
}

function PlayerGuessing() {
    const round = useRoundAtom().data;
    const roundUser = useRoundUserAtom().data;
    const chosenUser = useChosenUser().data;
    const socket = useSocketContext();
    const [voteInput, setVoteInput] = useState(roundUser?.vote || 0);
    const setVote = (x: number) => {
        setVoteInput(x);
        socket?.emit('edit-round-user', {
            changes: ['vote'],
            round_user: {
                ...roundUser,
                vote: x / 10,
            },
        } as Socket_API['SendToServer']['edit-round-user']['request']);
    };
    if (!round?.challenge?.story) return <div>loading...</div>;
    return (
        <div className="text-sm">
            <div className="font-thin">{chosenUser?.name} says:</div>
            <div className="text-xl font-bold">
                {round.challenge.story.replace('[[]]', round.challenge.keyword)}
            </div>
            <div className="flex justify-between w-full">
                <div>False</div>
                <div>True</div>
            </div>
            <Slider value={voteInput} onChange={setVote} />
            <pre>{JSON.stringify(round, null, '  ')}</pre>
            <pre>{JSON.stringify(roundUser, null, '  ')}</pre>
        </div>
    );
}
function Slider({value, onChange}: {value: number; onChange: (x: number) => void}) {
    const innerChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        try {
            const x = Number(e.target.value);
            if (isNaN(x)) return;
            onChange(x);
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <input
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            type="range"
            min="-10"
            max="10"
            step="1"
            value={value}
            onChange={innerChange}
        />
    );
}

function PlayerResult({leaderbaord}: {leaderbaord: LeaderboardUser}) {
    // show leaderboard points of player
    const user = useUserAtomFamily(leaderbaord.user_id).data;
    if (!user) return <div>loading...</div>;
    return (
        <div>
            <div>{user.name}</div>
            <div>{leaderbaord.points}</div>
        </div>
    );
}
function Results() {
    // show final points after last round
    const leaderboard = useLeaderboard().data;
    if (!leaderboard) return <div>loading...</div>;
    return (
        <div>
            <div>Results</div>
            <div className="w-full grid grid-cols-2 gap-1">
                {Object.values(leaderboard).map((user) => (
                    <PlayerResult key={user.user_id} leaderbaord={user} />
                ))}
            </div>
        </div>
    );
}
function LeaderboardPlayer({user: leaderboard}: {user: LeaderboardUser}) {
    const user = useUserAtomFamily(leaderboard.user_id).data;
    if (!user) return <div>loading...</div>;
    const dir = leaderboard.change > 0 ? '➕' : '➖';
    return (
        <>
            <div>{user.name}</div>
            <div>{leaderboard.vote * 10}</div>
            <div>
                {dir} {Math.abs(leaderboard.change)}
            </div>
            <div>{leaderboard.points}</div>
        </>
    );
}
function Leaderboard() {
    const leaderboard = useLeaderboard().data;
    console.log('LEADERBOARD', leaderboard);
    if (!leaderboard) return <div>loading...</div>;
    return (
        <div>
            <div className="text-xl font-bold">Leaderboard:</div>
            <div className="w-full grid grid-cols-4 gap-1">
                {Object.values(leaderboard).map((user) => (
                    <LeaderboardPlayer user={user} key={user.user_id} />
                ))}
            </div>
        </div>
    );
}

function Countdown() {
    const round = useRoundAtom().data;
    const timer = useRef<number>();
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (!round || round.finish_time < Date.now()) return;
        setCount((round.finish_time - Date.now()) / 1000);
        timer.current = setInterval(() => {
            if (!round || round.finish_time < Date.now()) clearInterval(timer.current);
            setCount((x) => x - 1);
        }, 1000);
        return () => clearInterval(timer.current);
    }, [round && round.finish_time]);
    return (
        <div className="animate-bounce text-1xl font-bold">
            Next phase in {count.toFixed(0)}!
        </div>
    );
}

function RoundStart() {
    return <div className="animate-bounce text-3xl font-bold">Loading</div>;
}

interface GameUserInput extends GameUser {
    update_id?: string;
}

function EditUser() {
    const user = useUserAtom().data;
    const socket = useSocketContext();
    const [userInput, setUserInput] = useState<Partial<GameUserInput> | null>(user);
    const setUserUpdate = (value: string, change: keyof GameUser) => {
        if (!user?.user_id) return;
        const newValue: Socket_API['SendToServer']['edit-user']['request'] = {
            changes: [change],
            users: [{...user, name: value, user_id: user.user_id}],
        };
        socket?.emit('edit-user', newValue);
    };
    return (
        <div>
            <div>Name: {user?.name}</div>
            <div>
                <Input
                    value={userInput?.name || ''}
                    onChange={(e) => {
                        setUserInput({...(user || {}), name: e.target.value});
                        setUserUpdate(e.target.value, 'name');
                    }}
                    onBlur={() => setUserInput(user)}
                />
            </div>
        </div>
    );
}

function EditGameLobby() {
    const game = useGameAtom().data;
    const user = useUserAtom().data;
    const socket = useSocketContext();
    const [gameInput, setGameInput] = useState<Game | null>(game);
    const setGameUpdate = <T extends keyof Game>(value: Game[T], change: T) => {
        if (!game) return;
        const gameUpdate: Game = {...game};
        gameUpdate[change] = value;
        const newValue: Socket_API['SendToServer']['edit-game']['request'] = {
            changes: [change],
            game: gameUpdate,
        };
        socket?.emit('edit-game', newValue);
    };
    useEffect(() => {
        if (user?.type !== 'host') {
            setGameInput(game);
        }
    }, [game, user]);
    if (!game || !user) return <div>loading...</div>;
    return (
        <div>
            <div>Name: {game.game_name}</div>
            <div>
                <Input
                    disabled={game?.host_user_id !== user?.user_id}
                    value={gameInput?.game_name + ''}
                    onChange={(e) => {
                        setGameInput({...game, game_name: e.target.value});
                        setGameUpdate(e.target.value, 'game_name');
                    }}
                    onBlur={() => setGameInput(game)}
                />
            </div>
            <div>Category: {game.category}</div>
            <div>
                <Input
                    disabled={game.host_user_id !== user.user_id}
                    value={gameInput?.category + ''}
                    onChange={(e) => {
                        setGameInput({...game, category: e.target.value});
                        setGameUpdate(e.target.value, 'category');
                    }}
                    onBlur={() => setGameInput(game)}
                />
            </div>
            <div>Round Limit: {game.round_limit}</div>
            <div>
                <Input
                    disabled={game.host_user_id !== user.user_id}
                    value={gameInput?.round_limit + ''}
                    onChange={(e) => {
                        setGameInput({...game, round_limit: Number(e.target.value)});
                        setGameUpdate(Number(e.target.value), 'round_limit');
                    }}
                    onBlur={() => setGameInput(game)}
                />
            </div>
            <div>Game Phase: {game.phase}</div>
            <div>
                <Button
                    onClick={() => setGameUpdate('round_start', 'phase')}
                    disabled={user.type !== 'host'}
                >
                    Start Game
                </Button>
            </div>
        </div>
    );
}
function UserDisplay({user_id}: {user_id: string}) {
    const user = useUserAtomFamily(user_id).data;
    if (!user) return <div>loading...</div>;
    return (
        <div className="flex gap-2">
            <span>{user.user_id}</span>
            <span>{user.name}</span>
            <span>{user.connection}</span>
            <span>{user.type}</span>
            <span>{user.avatar}</span>
        </div>
    );
}
function SubscribeViewUsers() {
    const users = useUserIdsAtom().data;
    return (
        <div>
            <div>USERS:</div>
            {users?.map((user_id) => (
                <UserDisplay key={user_id} user_id={user_id} />
            ))}
        </div>
    );
}

function Received() {
    const socket = useSocketContext();
    const [show, setShow] = useState<boolean>(false);
    const [received, setReceived] = useState<[string, object, string][]>([]);
    useEffect(() => {
        socket?.onAny((name, data, id) => {
            setReceived((x) => [...x.slice(-10, x.length), [name, data, id]]);
        });
        socket?.io.on('reconnect', () => {
            console.log('reconnected');
            setReceived((x) => [
                ...x.slice(-30, x.length),
                ['reconnected', new Date(), Math.random().toString(36)],
            ]);
        });
    }, [socket]);
    return (
        <div className="flex flex-col-reverse">
            {show &&
                received.map((x: [string, object, string]) => (
                    <div key={x[2]}>
                        {x[2]}
                        <pre className="text-xs">{JSON.stringify(x, null, '  ')}</pre>
                    </div>
                ))}
            <div>
                <Button onClick={() => setShow((x) => !x)}>
                    {show ? 'hide' : 'logs'}
                </Button>
            </div>
            <pre className="text-xs">
                socket:{' '}
                {socket?.connected
                    ? 'connected'
                    : socket?.io._readyState === 'opening'
                    ? 'connecting'
                    : 'disconnected'}
            </pre>
        </div>
    );
}

function useSocket(
    partyId: string | undefined,
    auth: {user_id: string | undefined; user_secret: string | undefined},
) {
    const [socket, setSocket] = useState<Socket>();
    useEffect(() => {
        if (!partyId || !auth.user_id) return;
        const s = io({
            query: {partyId},
            transports: ['websocket'],
            secure: true,
            auth,
        });
        // s.onAny((name, data, id) => {
        //     console.log('event', name, data, id);
        // });
        s.io.on('open', () => {
            s.send('hello', 'world', Math.random().toString(36));
        });
        s.io.on('reconnect', () => {
            console.log('reconnected');
        });
        setSocket(s);
        return () => {
            s.disconnect();
        };
    }, [partyId, auth.user_id, auth.user_secret]);
    return socket;
}

function ClientContainer({removeClient}: {removeClient: () => void}) {
    return (
        <div className="flex flex-col gap-2 p-2 border-2 w-full h-full">
            <div>
                <Button onClick={removeClient}>delete</Button>
            </div>
            <Client />
        </div>
    );
}

function App() {
    const [direction, setDirection] = useState<'row' | 'col'>('row');
    const [clients, setClients] = useState<string[]>(['1', '2']);
    const addClient = () => {
        setClients((x) => [...x, Math.random().toString(36)]);
    };
    const removeClient = (id: string) => {
        setClients((x) => x.filter((c) => c !== id));
    };
    const swapDirection = () => setDirection((x) => (x === 'row' ? 'col' : 'row'));
    return (
        <div
            className={clsx('flex justify-evenly w-screen p-1', {
                'flex-col': direction === 'col',
            })}
        >
            <Button onClick={swapDirection}>{direction === 'row' ? 'col' : 'row'}</Button>
            {clients.map((id) => (
                <ClientIdContext.Provider value={id} key={id}>
                    <ClientContainer key={id} removeClient={() => removeClient(id)} />
                </ClientIdContext.Provider>
            ))}
            <div>
                <Button onClick={addClient}>Add Client</Button>
            </div>
        </div>
    );
}

createRoot(document.getElementById('root')!).render(<App />);
