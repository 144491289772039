import {Socket} from 'socket.io-client';
import {atom} from 'jotai';
import {atomFamily, useAtomCallback} from 'jotai/utils';
import deepEqual from 'fast-deep-equal';
import {
    type GameUserInternal,
    type Game,
    type Round,
    type RoundUserInternal,
    type Socket_API,
    type GameUser,
    type LeaderboardUser,
} from './api';
import {createContext, useCallback, useContext, useEffect} from 'react';
import {useAtomValue, useSetAtom} from 'jotai/react';
import type {PrimitiveAtom} from 'jotai/vanilla';

type ComponentState<K, V> = {
    id: K;
    data: V;
};

const createAtomFamily = <K, V>(data: V) =>
    atomFamily<K, PrimitiveAtom<ComponentState<K, V>>>(
        (key: K) => atom<ComponentState<K, V>>({id: key, data}),
        deepEqual,
    );

export const ClientIdContext = createContext<string>('default');
export function useClientId() {
    return useContext(ClientIdContext);
}
type ClientId = string;
const gameAtom = createAtomFamily<ClientId, Game | null>(null);
export function useGameAtom() {
    return useAtomValue(gameAtom(useClientId()));
}
export function useSetGameAtom() {
    const setGame = useSetAtom(gameAtom(useClientId()));
    return (game: Game) => setGame(({id}) => ({id, data: game}));
}

const userAtom = createAtomFamily<ClientId, GameUserInternal | null>(null);
export function useUserAtom() {
    return useAtomValue(userAtom(useClientId()));
}
const userIdAtom = createAtomFamily<ClientId, string | null>(null);
export function useUserId() {
    return useAtomValue(userIdAtom(useClientId()));
}
export function useSetUserAtom() {
    const setUser = useSetAtom(userAtom(useClientId()));
    const setUserId = useSetAtom(userIdAtom(useClientId()));
    return (user: GameUserInternal | GameUser) => {
        setUser(({id, data}) => ({id, data: {...data, ...(user as GameUserInternal)}}));
        setUserId(({id}) => ({id, data: user.user_id}));
    };
}

const userIdsAtom = createAtomFamily<ClientId, string[]>([]);
export function useUserIdsAtom() {
    return useAtomValue(userIdsAtom(useClientId()));
}

const userAtomFamily = createAtomFamily<string, GameUser | null>(null);
export function useUserAtomFamily(user_id: string) {
    return useAtomValue(userAtomFamily(user_id));
}

function useSetGameUser(clientId: string) {
    return useAtomCallback(
        useCallback(
            (get, set, user: GameUser) => {
                set(userIdsAtom(clientId), (users) => {
                    const {id, data} = users;
                    if (data === null) return users;
                    if (data.includes(user.user_id)) {
                        return users;
                    }
                    return {id, data: [...data, user.user_id]};
                });
                set(userAtomFamily(user.user_id), {id: user.user_id, data: user});
            },
            [clientId],
        ),
    );
}

const roundsAtomFamily = createAtomFamily<string, Round | null>(null);
export function useRoundAtomFamily(round_id: string) {
    return useAtomValue(roundsAtomFamily(round_id));
}

export function useRoundAtom() {
    const game = useGameAtom();
    return useAtomValue(roundsAtomFamily(game.data?.round.round_id || ''));
}

function useSetRound() {
    return useAtomCallback(
        useCallback((get, set, round: Round) => {
            set(roundsAtomFamily(round.round_id), {id: round.round_id, data: round});
        }, []),
    );
}

export const roundUserAtom = createAtomFamily<string, RoundUserInternal | null>(null);
export function useRoundUserAtom() {
    return useAtomValue(roundUserAtom(useClientId()));
}

export function useChosenUser() {
    const round = useRoundAtom();
    const user = useUserAtomFamily(round.data?.chosen_user_id || '');
    return user;
}

export const roundLeaderboardAtom = createAtomFamily<
    string,
    Record<string, LeaderboardUser>
>({});

function useSetRoundLeaderboard() {
    return useAtomCallback(
        useCallback((get, set, round_id: string, leaderboard: LeaderboardUser[]) => {
            console.log(leaderboard);
            set(roundLeaderboardAtom(round_id), ({id, data}) => {
                const update = {...data};
                for (const user of leaderboard) {
                    update[user.user_id] = user;
                }
                console.log('UPDATE!', update);
                return {id, data: update};
            });
        }, []),
    );
}

export function useLeaderboard() {
    const round = useRoundAtom();
    return useAtomValue(roundLeaderboardAtom(round.data?.round_id || ''));
}

export function useSubscribeState(socket?: Socket) {
    const userId = useUserId().data;
    const clientId = useClientId();
    const setGame = useSetGameAtom();
    const setUser = useSetUserAtom();
    const setGameUser = useSetGameUser(clientId);
    const setRound = useSetRound();
    const setRoundLeaderboard = useSetRoundLeaderboard();
    const setRoundUser = useSetAtom(roundUserAtom(clientId));
    useEffect(() => {
        if (socket) {
            socket.on(
                'set-game',
                (game: Socket_API['ReceiveFromServer']['set-game']['value']) => {
                    setGame(game.game);
                },
            );
            socket.on(
                'set-user',
                (user: Socket_API['ReceiveFromServer']['set-user']['value']) => {
                    for (const userData of user.users) {
                        setGameUser(userData);
                        if (userData.user_id === userId) {
                            setUser(userData);
                        }
                    }
                },
            );
            socket.on(
                'set-round',
                (round: Socket_API['ReceiveFromServer']['set-round']['value']) => {
                    for (const roundData of round.rounds) {
                        setRound(roundData);
                    }
                },
            );
            socket.on(
                'set-round-user',
                (
                    round_user: Socket_API['ReceiveFromServer']['set-round-user']['value'],
                ) => {
                    setRoundUser((x) => ({id: x.id, data: round_user.round_user}));
                },
            );
            socket.on(
                'set-leaderboard',
                (data: Socket_API['ReceiveFromServer']['set-leaderboard']['value']) => {
                    console.log(data);
                    if (!data.length) return;
                    const round_id = data[0].round_id;
                    setRoundLeaderboard(round_id, data);
                },
            );
            return () => {
                socket.off('set-game');
                socket.off('set-user');
                socket.off('set-round');
                socket.off('set-round-user');
                socket.off('set-leaderboard');
            };
        }
    }, [socket]);
}
